import { I18n } from "i18n-js";

const i18n = new I18n({
  en: {
    unable_to_complete_payment: "Unable to complete payment.",
    unable_to_pay_for_order: "Unable to pay for order.",
    invalid_order_id: "Invalid Order ID specified.",
    unable_to_tokenize: "Unable to tokenize payment details.",
    invalid_card: "Invalid card number supplied.",
    invalid_cardholder: "Cardholder name must be between 3 and 30 characters long.",
    invalid_cvd: "Invalid CVC supplied.",
    invalid_expiry: "Invalid expiry date supplied.",
    invalid_postal_code: "Invalid Zip/Postal code supplied.",
    cardnumber_required: "Card number must be supplied.",
    expiry_date_required: "Expiry date must be supplied.",
    cvd_required: "CVC must be supplied.",
    address1_required: "Address 1 must be supplied.",
    unable_to_process: "Currently unable to process payment.",
    unable_to_tokenize: "Currently unable to tokenize payment details.",
    google_pay_not_available: "Google Pay is not available. Please choose a different payment method."
  },
  es: {
    unable_to_complete_payment: "No se puede completar el pago.",
    unable_to_pay_for_order: "No se puede pagar el pedido.",
    invalid_order_id: "ID de pedido especificado no válido",
    unable_to_tokenize: "No se pueden tokenizar los detalles de pago.",
    invalid_card: "Número de tarjeta proporcionado no válido.",
    invalid_cardholder: "El nombre del titular de la tarjeta debe tener entre 3 y 30 caracteres.",
    invalid_cvd: "CVC suministrado no válido.",
    invalid_expiry: "Fecha de caducidad proporcionada no válida.",
    invalid_postal_code: "Codigo postal no valido.",
    cardnumber_required: "Se debe proporcionar el número de tarjeta.",
    expiry_date_required: "Se debe proporcionar la fecha de caducidad.",
    cvd_required: "Se debe proporcionar CVC.",
    address1_required: "Se debe proporcionar una direccion.",
    unable_to_process: "Actualmente no se puede procesar el pago.",
    unable_to_tokenize: "Actualmente no se pueden tokenizar los detalles de pago.",
    google_pay_not_available: "Google Pay no está disponible. Por favor, seleccione otra forma de pago."
  },
});

export { i18n as default };
