import Components from "js/components"
import EventHandler from "js/events"
import i18n from "js/translations"
import EpApplePay from "js/apple_pay"

const ExactJS = function (shareableKey, opts) {
  let origin = "https://INSERT_DOMAIN_HERE";
  const merchantDomain = window.location.host;
  const options = opts || {};
  let orderId;
  let components;
  const handlers = EventHandler();

  // allow merchant to specify, or grab from browser. default to English
  let locale = options.locale || navigator.language || "en-US";
  locale = locale.split("-")[0];
  if (!["en", "es"].includes(locale)) {
    locale = "en";
    i18n.locale = locale;
  }

  // coordinator frame to handle comms with our APIs
  console.debug(`ExactJS: creating coordinator`);
  let coordinator = document.createElement('iframe');
  coordinator.name = `exactpay_coordinator_${Math.round(Math.random() * 10000)}`;
  coordinator.src = `${origin}/components/coordinator`;
  coordinator.style = "display:none";
  coordinator.sandbox = "allow-scripts allow-same-origin"
  coordinator.onload = () => coordinator.contentWindow.postMessage({ msg: "register-merchant-key", payload: { key: shareableKey, locale: locale } }, origin);

  document.body.append(coordinator);

  const applePay = EpApplePay(origin, coordinator);
  const applePayAvailable = applePay.checkAvailability();

  const attemptPayment = (orderId) => {
    if ((orderId === undefined) || (typeof orderId !== "string") || orderId.length < 20) {
      handlers.process("payment-failed", [i18n.t("invalid_order_id")]);
      return;
    }

    // tell the coordinator to attempt a payment for the given orderId, using PCI data retrieved from the given frames.
    console.debug(`ExactJS: requesting payment attempt from coordinator frame`);
    coordinator.contentWindow.postMessage({ msg: "attempt-payment", payload: { orderId: orderId } }, origin);
  };

  const attemptTokenization = (orderId) => {
    if ((orderId === undefined) || (typeof orderId !== "string") || orderId.length < 20) {
      handlers.process("payment-failed", [i18n.t("invalid_order_id")]);
      return;
    }

    // tell the coordinator to attempt to tokenize the PCI data retrieved from the given frames.
    console.debug(`ExactJS: requesting tokenization attempt from coordinator frame`);
    coordinator.contentWindow.postMessage({ msg: "attempt-tokenization", payload: { orderId: orderId } }, origin);
  };

  // let child frames know how to contact the coordinator
  const registerWithCoordinator = (frameName, frameType) => {
    coordinator.contentWindow.postMessage({ msg: "register-frame", payload: { name: frameName, type: frameType } }, origin);
  }

  const cleanup = () => {
    console.log("EXACTJS: reset")
    coordinator.contentWindow.postMessage({ msg: "unmount" }, origin);
    const iframes = document.body.getElementsByTagName('iframe');
    Array.from(iframes).forEach(ifr => {
      if (ifr.name.startsWith("exactpay_frame_")) {
        console.log(`FOUND FRAME: ${ifr.name}`)
        ifr.remove();
      }
    })
    window.removeEventListener("message", messageHandler);
    coordinator.remove();
    coordinator = null;
    return true
  }

  const messageHandler = (event) => {
    if (event.origin !== origin) {
      return; // ignore it
    }
    switch (event.data.msg) {
      case "frame-loaded":
        console.debug(`ExactJS: Frame ${event.data.payload.type} loaded - width: ${event.data.payload.width}, height: ${event.data.payload.height}`);
        const frameName = components.resizeFrame(event.data.payload.type, event.data.payload.width, event.data.payload.height);
        registerWithCoordinator(frameName, event.data.payload.type);
        break;
      case "start-apple-pay":
        if (applePayAvailable) {
          applePay.initiatePayment(event.data.payload);
        }
        break;
      case 'payment-complete':
        console.debug(`ExactJS: completed payment: ${JSON.stringify(event.data.payload)}`);
        if (event.data.payload.orderId === orderId) {
          const { orderId, ...payload } = event.data.payload;
          handlers.process("payment-complete", payload);
        }
        break;
      case 'payment-fail':
        console.debug(`ExactJS: payment failed: ${JSON.stringify(event.data.payload)}`);
        if (event.data.payload.orderId === orderId) {
          handlers.process("payment-failed", event.data.payload.messages);
        }
        break;
      case 'form-valid':
        console.debug(`ExactJS: minimum viable payment: ${JSON.stringify(event.data.payload)}`);
        handlers.process("form-valid", { valid: event.data.payload.mvp });
        break;
    }
  };

  window.addEventListener("message", messageHandler);

  return {
    components: (opts) => {
      if (components === undefined) {
        orderId = opts.orderId;
        components = Components(origin, {
          locale: locale,
          orderId: opts.orderId,
          applePay: applePayAvailable,
          merchantDomain: merchantDomain
        });
      }
      return components;
    },

    on: (eventName, handler) => {
      handlers.on(eventName, handler);
    },

    payOrder: () => {
      return attemptPayment(orderId);
    },

    tokenize: () => {
      return attemptTokenization(orderId);
    },

    reset: () => {
      return cleanup();
    }
  }
};

export { ExactJS as default }
