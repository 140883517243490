const qs = require('qs');

const Components = (apiDomain, options) => {
  console.debug(`Components: ${options}`)
  const children = [];
  const origin = apiDomain;
  const locale = options.locale;
  const orderId = options.orderId;
  const applePayAvailable = options.applePay;
  const merchantDomain = options.merchantDomain;
  const partialCardElements = ['cardholder-name', 'card-number', 'expiry-date', 'cvd'] + [partialAddressElement];
  const partialAddressElement = 'address'
  const fullCardElement = 'full-card';
  const allElements = partialCardElements + [partialAddressElement] + [fullCardElement];

  const createFrame = (contentUrl) => {
    const frame = document.createElement('iframe');
    frame.name = `exactpay_frame_${Math.round(Math.random() * 10000)}`;;
    frame.allowtransparency = true;
    frame.sandbox = "allow-scripts allow-same-origin allow-forms allow-popups"
    frame.scrolling = "no";
    frame.src = contentUrl;
    frame.allow = "payment"
    return frame;
  };

  const elementAlreadyAdded = (elementType) => {
    const addedComponents = Array.from(children).flatMap(child => child[1])

    // you cannot add a component if it already exists
    if (addedComponents.includes(elementType)) {
      console.log(`${elementType} element request rejected - component already exists.`);
      return true;
    }

    // you can only add a component if a full-card doesn't alteady exist, unless
    // you are trying to add an address and you did not include the address in the
    // full-card component
    if (addedComponents.includes("full-card") && elementType !== "address") {
      console.log(`${elementType} element request rejected as 'full-card' component already exists.`);
      return true;
    }

    return false
  };

  const add = (parentDivId, elementToAdd, options) => {
    if (!allElements.includes(elementToAdd)) {
      console.log(`The element: "${elementToAdd}" is not included in the list of known elements.`);
      throw `Error: Cannot add unknown component "${elementToAdd}"`;
    }

    if (elementAlreadyAdded(elementToAdd)) {
      throw `Error: Cannot add "${elementToAdd}" component - a "full-card" component or another "${elementToAdd}" component already exists on page.`;
    }

    const requestedElement = elementToAdd.replace('-', '_');
    options ||= {};
    options.locale = locale;
    options.order_id = orderId;
    if (elementToAdd == "full-card") {
      options.apple_pay = applePayAvailable;
      options.merchant_domain = merchantDomain;
    }
    console.debug(`Components: URL params: ${options}`)
    let srcUrl = new URL(`${origin}/components/${requestedElement}?${qs.stringify(options)}`);

    const frame = createFrame(srcUrl);

    if (elementToAdd === "full-card" && options.billingAddress) {
      // note that we're really adding card + address
      elementToAdd = ["full-card", "address"]
    }

    children.push([frame.name, elementToAdd]);

    const parentDiv = document.getElementById(parentDivId);
    parentDiv.append(frame);
  };

  return {
    resizeFrame: (type, width, height) => {
      const frameName = children.find(elem => [elem[1]].flat().includes(type))[0];
      const frame = document.getElementsByName(frameName)[0];
      frame.width = width;
      frame.height = height;
      return frameName;
    },

    addComponent: (parentDivId, elementToAdd, options) => {
      add(parentDivId, elementToAdd, options)
    },

    addCard: (parentDivId, options) => {
      add(parentDivId, "full-card", options);
    }
  }
}

export { Components as default };
