const EventHandler = () => {
  const handlers = [];

  return {
    on: (eventName, handler) => {
      handlers.push([eventName, handler]);
    },

    process: (eventName, payload) => {
      console.log(`Processing event ${eventName} with payload ${JSON.stringify(payload)}`);
      const handler = handlers.find(elem => elem[0] === eventName);
      if (handler) {
        handler[1](payload);
      } else {
        console.warn(`Merchant has not defined an event handler for ${eventName}`);
      }
    }
  }
};

export { EventHandler as default };
