const EpApplePay = (apiOrigin, coordinatorFrame) => {
  const origin = apiOrigin;
  const coordinator = coordinatorFrame;

  const performValidation = (orderId) => {
    return new Promise((resolve, reject) => {
      // tell the coordinator to initialise Apple Pay for the given orderId
      console.debug(`AP: requesting ApplePay session from coordinator frame`);
      coordinator.contentWindow.postMessage({ msg: "init-apple-pay", payload: { hostDomain: window.location.host, orderId: orderId } }, origin);

      window.addEventListener("message", event => {
        if (event.origin === origin) {
          switch (event.data.msg) {
            case 'apple-pay-session':
              console.debug(`AP: ApplePay session received`);
              resolve(event.data.payload.session);
              break;
            case "apple-pay-session-fail":
              reject(event.data.payload.error)
              break;
          }
        }
      })
    })
  };

  const sendPayment = (apPayment, orderId) => {
    return new Promise((resolve, reject) => {
      console.debug(`AP: requesting ApplePay payment from coordinator frame`);
      coordinator.contentWindow.postMessage({ msg: "complete-apple-pay", payload: { payload: apPayment, orderId: orderId } }, origin);

      window.addEventListener("message", event => {
        if (event.origin === origin) {
          switch (event.data.msg) {
            case 'wallet-payment-complete':
              resolve(true);
              break;
            case "wallet-payment-fail":
              reject(event.data.payload)
              break;
          }
        }
      });
    })
  };

  const onApplePayClicked = (payload) => {
    const orderId = payload.orderId;
    const label = payload.label;
    const amount = payload.amount;

    var request = {
      countryCode: payload.countryCode,
      currencyCode: payload.currencyCode,
      supportedNetworks: JSON.parse(payload.supportedCards),
      merchantCapabilities: ["supports3DS", "supportsCredit"],
      requiredBillingContactFields: ["postalAddress", "name", "phone", "email"],
      total: { label: label, amount: amount }
    }

    // using version 2, minimum required for our needs
    const session = new ApplePaySession(2, request);

    session.onvalidatemerchant = (event) => {
      performValidation(orderId)
        .then(merchantSession => {
          try {
            session.completeMerchantValidation(merchantSession);
          } catch (err) {
            console.debug(`AP: Complete merchant validation failed: ${err.message}`);
            session.abort();
          }
        })
        .catch(reason => {
          console.debug(`AP: Merchant validation failed: ${reason}`)
          session.abort();
        });
    };

    session.onpaymentauthorized = (event) => {
      sendPayment(event.payment, orderId)
        .then(success => {
          console.debug(`AP: payment completed: ${success}`);
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
        })
        .catch(err => {
          // no need to send the error back as AP sheet won't display it
          session.completePayment(ApplePaySession.STATUS_FAILURE);
        });
    };

    // TODO: we should update order based on this
    session.onshippingcontactselected = (event) => console.debug("AP: onshippingcontactselected");

    // we don't support a shipping method
    session.onshippingmethodselected = (event) => console.debug("AP: onshippingmethodselected");

    session.onpaymentmethodselected = (event) => {
      console.debug(`AP: Payment method type ${event.paymentMethod.type} selected.`);
      session.completePaymentMethodSelection({
        newTotal: { type: "final", label: label, amount: amount }
      });
    };

    session.oncancel = (event) => console.debug("AP: Session cancelled.");

    session.begin();
  };

  return {
    checkAvailability: () => {
      return window.ApplePaySession && ApplePaySession.canMakePayments();
    },

    initiatePayment: (payload) => {
      console.debug(`AP: payload - ${payload}`);
      return onApplePayClicked(payload);
    }
  }
};

export { EpApplePay as default };
